<template>
  <div class="iss-main-h5">
    <div class="preview-content">
      <div class="title fs-18">
        {{ templateContent.title }}
      </div>
      <a-form>
        <a-form-item
          v-for="(value, key) in contentMap"
          :key="key"
          :label="value == '*' ? '' : value"
        >
          <span style="color: #9d9d9d; word-break: break-all">{{
            form[key]
          }}</span>
        </a-form-item>
      </a-form>
      <a class="prewiew-button" :href="form.url">
                <span>详情</span><RightOutlined />
      </a>
    </div>
  </div>
  </template>

  <script>
  import { reactive, toRefs, onMounted } from 'vue';
  import { Form } from 'ant-design-vue';
  import { useRoute} from 'vue-router';
  import wechatApi from '@/api/wechatServer';
  import { useStore } from 'vuex';
  import { RightOutlined } from '@ant-design/icons-vue';
  export default {
    components: {
      AForm: Form,
      AFormItem: Form.Item,
      RightOutlined
    },
    setup() {
      const route = useRoute();
      const store = useStore();
      const detailId = route.query.id;
      const wechatTemplateId= route.query.wechatTemplateId
      const token = route.query.token
      const tenant = route.query.tenant
      store.commit('account/setToken', token);
      store.commit('account/setTenant', tenant);
      const templateListState = reactive({
        appId: '',
        templateContent: {},
        contentMap: {},
        templateContentDetail: [],
      });
      const form = reactive({
        title: '',
        url: '',
        templateType: 0,
        trackClick: false,
      });
      //获取模版内容 (编辑)
      const getTemplateContentByWechatFun = id => {
        wechatApi.getTemplateByWechatId({}, { templateId: id }).then(res => {
          if (!res) {
            console.log(res);
          } else {
            templateListState.templateContent = res;
            templateListState.contentMap = res.contentMap;
          }
        });
      };
      const getSendTemplateContentFun = async (id, wechatTemplateId) => {
        await getTemplateContentByWechatFun(wechatTemplateId);
        wechatApi
          .getSendTemplateById({}, { id, appId: store.state.account.appId })
          .then(res => {
            if (!res) {
              console.log(res);
            } else {
              form.title = res.templateName;
              form.templateType = res.templateType;
              form.trackClick = res.trackClick;
              form.url = res.url;
              for (let key in res.templateContentMap) {
                form[key] = res.templateContentMap[key];
              }
              console.log('模版内容', templateListState.templateContent);
            }
          });
      };
      onMounted(async () => {
        await wechatApi.getTenantId().then(res => {
          if (!res) {
            console.log(res);
          } else {
            templateListState.appId = res.appId[0].appId;
            store.commit('account/setAppId', res.appId[0].appId);
          }
        });
        getSendTemplateContentFun(detailId, wechatTemplateId);
      });
      return {
        ...toRefs(templateListState),
        form,
      };
    },
  };
  </script>

  <style lang="less" scoped>
  .iss-main-h5{
    width: 100%;
     height: 100vh;
    background: #E7E7E7;
    padding: 20px 16px;
    overflow: hidden auto;
    .preview-content {
      background: #fff;
      padding: 20px;
      border-radius: 4px;
      width: 90%;
      min-height: 320px;
      margin: 0 auto;
      position: relative;
    }
  }
  .prewiew-button{
  border-top: 1px solid #BBBBBB;
  padding: 10px 0;
  cursor: pointer;
  color:#9d9d9d ;
  display: inline-block;
  width: 100%;
}
  </style>
